import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

function ShopsPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `Автоматизация магазинов`,
          `Торговое оборудование`,
          `Розница`,
          `онлайн-кассы Пыть-Ях`,
          `ЕГАИС`,
          `Маркировка`,
        ]}
        title='Торговля'
      />

      <section className='flex flex-col items-center  px-6'>
        <h2 className='text-2xl text-center uppercase m-6 p-4 tracking-wider'>
          Автоматизация оптовых и розничных магазинов
        </h2>
        <p className='text-lg text-center  m-6 p-4 bg-blue-200'>
          Мы имеем опыт автоматизации как розничных продуктовых сетей
          минимаркетов, так и отдельных продуктовых и других магазинов.
        </p>
        <p className='text-lg bg-teal-200 text-center m-6 p-4'>
          Автоматизация под ключ. Начиная с выбора оборудования и программного
          обеспечения и до дальнейшего регулярного сопровождения.
        </p>
        <p className='text text-red-600 text-center m-6 p-4'>
          Каждый кейс автоматизации - индвидуален. Напишите нам или позвоните.
          Подберем для Вас оптимальный вариант по производительности и цене.
        </p>
      </section>
    </Layout>
  );
}

export default ShopsPage;
